:root {
  --bleu-kivabien: #445D93;
  --select-border: #b2b2b2;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

/* units, etc. */
.dp_unit {
  font-size: 1.6rem;
}

.dp_container label {
  font-size: 1.6rem;
}

.dp_entry, .dp_radio_label {
  font-size: 1.6rem !important;
}

.dp_field_container .dp_input {
  font-size: 1.6rem;
  padding: .5rem;
}

.dp_field_container {
  margin: 0 0 3rem;
}

.dp_field_container label {
  display: inline-block;
}

fieldset.dp_group {
  padding: 0 0 1.2rem 0;
  margin: 0 0 3rem;
  border: none !important;
  border-bottom: 1px solid #464646;
}

.dp_group_label, .dp_group legend {
  padding: 0;
  font-size: 2rem;
  background: transparent;
  font-weight: bold;
  color: #464646;
}

.dp_radio {
  display: flex;
  //justify-content: space-between;
  flex-wrap: wrap;
}

.dp_radio .dp_option {
  display: flex;
  flex-direction: row;
  padding: 1.2rem;
  border: 1px solid #B2B2B2;
  border-radius: 8px;
  margin: 0 1.2rem 1.2rem 0;
  width: calc(100% / 2 - 15px);
}

.dp_radio .dp_option .dp_radio_label {
  margin-left: 1rem;
}

.dp_radio {
  .dp_option {
    display: flex;
    align-items: center;
  }
}


.dp_dropdown {
  border-radius: 8px;
  width: calc(100% / 2 - 15px);
  padding: 1.2rem !important;
}

.form-control {
  height: auto;
}

// 1.7 module dynamic
.dp_type_dropdown {
  .form-control {
    border-radius: 8px;
    border: 1px solid #B2B2B2;
    width: calc(100% / 2 - 15px);
    padding: 1.2rem !important;
  }
}

.dp_input_container .ui-corner-all
{
  width: calc(100% - 50px);
  input {
    width: 100%;
  }
}

.dp_type_radio {
  .dp_input_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    label {
      border-radius: 8px;
      border: 1px solid #B2B2B2;
      width: calc(100% / 2 - 15px);
      padding: 1.2rem !important;
      word-break: break-word;
      :hover {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.dp_input_container {
  max-width: 100%;
  width: 100%;

  .form-control {
    border-radius: 8px;
    padding: 1.2rem !important;
  }
}

/*
* image list
 */

.dp_type_image-list {
  .dp_input_container {
    li {
      position: relative;

      .option_label {
        position: absolute;
        display: none;
      }

      &:hover {
        .option_label {
          display: block;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          padding: 1rem;
          margin-top: .5rem;
          overflow: visible;
          text-overflow: unset;
          width: unset;
          bottom: 0;
        }
      }
    }
  }
}

.dp_type_divider {
  margin-top: 3rem;
  margin-bottom: 1.2rem;
}

.dp_type_html {
  .attribute_label {
    letter-spacing: 1px;
    font-size: 1.8rem;
    font-weight: bold;
    color: #464646;
  }
}

.dp_tooltip_btn i.material-icons {
  font-style: unset !important;
}

.dp_link {
  color: #FFC400 !important;
}

.checkbox-checked {
  display: none;
}

.dp_type_dropdown .dp_input_container{
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
    box-shadow: none;
  }

  width: calc(50% - 15px);

  //min-width: 15ch;
  //max-width: 30ch;

  border: 1px solid var(--select-border);
  border-radius: 8px;
  padding: 0.25em 0.5em;

  font-size: 1.6rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  background-color: #fff;
  //background-image: linear-gradient(to top, #f9f9f9, #fff 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}

  .dp_input_container select.form-control {
    // A reset of styles, including removing the default dropdown arrow
    appearance: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;

    // Stack above custom arrow
    z-index: 1;

    // Remove dropdown arrow in IE10 & IE11
    // @link https://www.filamentgroup.com/lab/select-css.html
    &::-ms-expand {
      display: none;
    }

    // Remove focus outline, will add on alternate element
    outline: none;
  }

  /* CHECKBOX */
  .custom-checkbox i {
    display: none;
  }

  /* ui-spinner */

  .dp_input_container {
    .ui-spinner {
      border-radius: 8px !important;
      border: 1px solid #B2B2B2;
      width: calc(100% / 2 - 15px);

      .ui-spinner-button {
        width: 40px;

        .ui-button-text {
          width: 100%;
        }
      }

      input {
        height: auto;
        box-sizing: border-box;
        padding: 1.2rem !important;
        border: none;
        appearance: none;
      }
    }

    ul {
      display: grid !important;
      display: grid;
      grid-gap: 5px;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      //grid-template-rows: repeat(auto-fit, 200px);
      li{
        img {
          max-width: 100%;
        }
      }
    }
  }

  /* ICON */

  .dp-btn-round {
    width: 36px !important;
    height: 36px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .dp_zoom {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dp_zoom .material-icons, i.material-icons.svelte-1thd3c1 { //icone loupe
    font-size: 32px !important;
    color: var(--bleu-kivabien);
  }

  .li.svelte-1thd3c1.svelte-1thd3c1 // grille couleur
  {
    overflow: hidden;
  }

  .btn.dp-lightbox-btn {
    color: #fff;
    border: #fff 1px solid;
    background-color: var(--bleu-kivabien);
    font-size: 1.6rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: .5rem 1rem;

    &:hover {
      background-color: white;
      color: var(--bleu-kivabien);
    }

    .material-icons {
      font-size: 1.6rem;
    }
  }

.dp_type_image-list .dp_input_container li:hover {
  .dp_zoom {
    opacity: 1 !important;
  }
  .option_label {
    position: absolute;
    display: block;
    background-color: rgba(0, 0, 0, .6);
    border-radius: 0px;
    padding: 0.3rem 1.6rem;
    //margin-top: 0.5rem;
    overflow: visible;
    text-overflow: unset;
    height: 3rem;
    top: 100%;
    width: 100%;
    color: white;
    z-index: 100;
  }
}